import { useEffect, useState } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import { HiSpeakerWave, HiSpeakerXMark } from "react-icons/hi2";
import { MdOutlineForward10, MdOutlineReplay10 } from "react-icons/md";
import styles from "./VideoTimeline.module.css";

function VideoTimeline({
  timeValue,
  durationAmount,
  onTimeUpdate,
  onPlayPause,
  onMute,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [soundIsOn, setSoundIsOn] = useState(true);
  const [timelineValue, setTimelineValue] = useState(true);
  const [textTime, setTextTime] = useState(timeValue);

  useEffect(() => {
    let hours = Math.floor(timeValue / 3600);
    let minutes = Math.floor((timeValue / 3600 - hours) * 60);
    let seconds =
      Math.floor((timeValue - hours * 3600 - minutes * 60) * 1000) / 1000;
    let hoursText = hours > 10 ? `${hours}` : `0${hours}`;
    let minutesText = minutes > 10 ? `${minutes}` : `0${minutes}`;
    let secondsText = seconds > 10 ? `${seconds}` : `0${seconds}`;
    setTextTime(`${hoursText} : ${minutesText} : ${secondsText}`);
  }, [timeValue]);
  useEffect(() => {
    onTimeUpdate(timelineValue); // eslint-disable-next-line
  }, [timelineValue]);

  const handleChange = (value) => {
    setTimelineValue(Number(value));
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.timelineContainer}>
        <input
          type="range"
          name="timeLine"
          id="timeLine"
          className={styles.input}
          value={timeValue}
          step={0.1}
          max={isNaN(durationAmount) ? 100 : durationAmount}
          onChange={(event) => handleChange(event.target.value)}
        />
        <input
          className={styles.textTime}
          type="text"
          name="timeText"
          id="timeText"
          value={textTime}
        />
        <button
          disabled={timelineValue < 10}
          className={styles.backForButton}
          onClick={() => setTimelineValue(timelineValue - 10.0)}
        >
          <MdOutlineReplay10 className={styles.icons} />
        </button>
        <button
          disabled={durationAmount === 0}
          className={styles.playButton}
          onClick={() => {
            setIsPlaying(!isPlaying);
            onPlayPause(isPlaying);
          }}
        >
          {isPlaying ? (
            <FaPause className={styles.icons} />
          ) : (
            <FaPlay className={styles.icons} />
          )}
        </button>
        <button
          disabled={timelineValue >= durationAmount - 10}
          className={styles.backForButton}
          onClick={() => setTimelineValue(timelineValue + 10.0)}
        >
          <MdOutlineForward10 className={styles.icons} />
        </button>
        <button
          disabled={durationAmount === 0}
          className={styles.backForButton}
          onClick={() => {
            setSoundIsOn(!soundIsOn);
            onMute();
          }}
        >
          {soundIsOn ? (
            <HiSpeakerWave className={styles.icons} />
          ) : (
            <HiSpeakerXMark className={styles.icons} />
          )}
        </button>
      </div>
    </div>
  );
}

export default VideoTimeline;
