import { Modal } from "react-bootstrap";
import styles from "./AddProjectPopup.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import AppDataContext from "../../contexts/AppDataContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function AddProjectPopup({ show, onClose }) {
  const originOptions = ["English", "Farsi", "French", "Deutsch"];
  const translateOptions = ["", "English", "Farsi", "French", "Deutsch"];
  const uploadFileEndpoint = "/transcript/upload_file/";
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [buttonDisable, setButtonDisable] = useState(true);
  const [fileInputLabel, setFileInputLabel] = useState(
    "Upload your video here"
  );
  const [languageData, setLanguageData] = useState({
    original_language: "en",
    translate_language: "",
  });
  const {
    handleUpload,
    handleSubtitleTrack,
    baseURL,
    uploadParams,
    setIsLoadingValue,
    setUploadParamValues,
  } = useContext(AppDataContext);

  useEffect(() => {
    if (uploadParams.url && videoRef.current?.files) {
      axios
        .put(uploadParams.url, videoRef.current.files[0], {
          redirect: "follow",
          headers: { "Content-Type": "" },
        })
        .then(() => {
          navigate(`/${uploadParams.file_id}`, {
            state: {
              filename: videoRef.current.value.split("\\")[2],
            },
          });
        })
        .catch((err) => console.log("Put to MinIo:", err));
    } // eslint-disable-next-line
  }, [uploadParams]);

  const inputFileIsValid = (file) => {
    if (file.current.files[0].type === "video/mp4") {
      return true;
    } else {
      return false;
    }
  };

  const handleUploadClick = () => {
    setIsLoadingValue({
      condition: true,
      message: "Uploading your video file ... ",
    });
    setButtonDisable(true);
    handleUpload(videoRef);
    if (inputFileIsValid(videoRef)) {
      handleSubtitleTrack();
      axios
        .post(
          `${baseURL}${uploadFileEndpoint}`,
          { file_name: videoRef.current.value.split("\\")[2], ...languageData },
          { withCredentials: true }
        )
        .then((res) => setUploadParamValues(res.data))
        .catch((err) => {
          console.log("Post Filename", err);
          navigate("login");
        });
    } else {
      setFileInputLabel('**You have to upload a "video.mp4" file **');
    }
  };
  const handleFileInputChange = (event) => {
    setButtonDisable(false);
    setFileInputLabel(event.target.value.split("\\")[2]);
  };

  const handleLanguageSelect = (event, property) => {
    const languageCode = event.target.value.toLowerCase().substr(0, 2);
    setLanguageData({ ...languageData, [property]: languageCode });
  };

  useEffect(() => {
    if (languageData.original_language === languageData.translate_language) {
      setLanguageData({ ...languageData, translate_language: "" });
    }
  }, [languageData]);

  return (
    <div className={styles.mainContainer}>
      <Modal show={show} onHide={onClose} centered className={styles.modal}>
        <Modal.Body>
          <div className={styles.popupBodyContainer}>
            <div className={styles.videoDropContainer}>
              <form
                id="newProjectForm"
                className={styles.form}
                encType="multipart/form-data"
              >
                <div
                  className={
                    "row justify-content-center " + styles.formRowContainer
                  }
                >
                  <div className={styles.inputFileContainer}>
                    <label htmlFor="uploadVideo" className={styles.inputLabel}>
                      {fileInputLabel}
                    </label>
                    <input
                      className={styles.inputFile}
                      type="file"
                      name="uploadVideo"
                      ref={videoRef}
                      accept=".mp4"
                      onChange={(event) => handleFileInputChange(event)}
                      id="uploadVideo"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className={styles.videoFooterContainer}>
              <div className={styles.languageContainer}>
                <div className={styles.labelContainer}>
                  <label htmlFor="Origins" className={styles.selectLabel}>
                    Origin:
                  </label>
                </div>
                <div className={styles.selectContainer}>
                  <select
                    name="Origins"
                    id="Origins"
                    className={styles.SelectionPop}
                    onChange={(event) =>
                      handleLanguageSelect(event, "original_language")
                    }
                  >
                    {originOptions.map((option, index) => (
                      <option key={index}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.languageContainer}>
                <div className={styles.labelContainer}>
                  <label htmlFor="Translates" className={styles.selectLabel}>
                    Translate to:
                  </label>
                </div>
                <div className={styles.selectContainer}>
                  <select
                    name="Translates"
                    id="Translates"
                    className={styles.SelectionPop}
                    onChange={(event) =>
                      handleLanguageSelect(event, "translate_language")
                    }
                  >
                    {translateOptions.map((option, index) => (
                      <option key={index}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={styles.footerContainer}>
            <button
              className={styles.popupButton + " " + styles.popupCancel}
              onClick={() => {
                videoRef.current = null;
                setFileInputLabel("Upload your video here");
                setButtonDisable(true);
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              disabled={buttonDisable}
              type="submit"
              className={styles.popupButton + " " + styles.popupSatrt}
              onClick={handleUploadClick}
            >
              Start
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddProjectPopup;
